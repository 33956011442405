const portfolio_data = [
  {
    id: 1,
    nazev: "Sauna",
    informace: "",
  },
  {
    id: 2,
    nazev: "Café",
    informace: "",
  },
  {
    id: 3,
    nazev: "Parter",
    informace: "",
  },
  {
    id: 4,
    nazev: "Parter",
    informace: "",
  },
  {
    id: 5,
    nazev: "Axonometrie",
    informace: "",
  },
  {
    id: 6,
    nazev: "Schwarzplan",
    informace: "M 1 : 5000",
  },
  {
    id: 7,
    nazev: "Situace",
    informace: "M 1 : 1000",
  },
  {
    id: 9,
    nazev: "Skici",
    informace: "",
  },
  {
    id: 10,
    nazev: "Skici",
    informace: "",
  },
  {
    id: 11,
    nazev: "Skici",
    informace: "",
  },
  {
    id: 12,
    nazev: "Skici",
    informace: "",
  },
  {
    id: 13,
    nazev: "Skici",
    informace: "",
  },
  {
    id: 14,
    nazev: "Skici",
    informace: "",
  },
  {
    id: 15,
    nazev: "Skici",
    informace: "",
  },
  {
    id: 16,
    nazev: "Skici",
    informace: "",
  },
  {
    id: 17,
    nazev: "Axonometrie podlaží",
    informace: "",
  },
  {
    id: 18,
    nazev: "1. NP",
    informace: "M 1 : 200",
  },
  {
    id: 19,
    nazev: "2. NP",
    informace: "M 1 : 200",
  },
  {
    id: 20,
    nazev: "Pohled z ulice",
    informace: "M 1 : 300",
  },
  {
    id: 21,
    nazev: "Řez",
    informace: "M 1 : 300",
  },
];

export default portfolio_data;