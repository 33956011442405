import React, { useState } from "react";
import FsLightbox from "fslightbox-react";

export default function PortfolioItem({ item }) {
    const [open, setOpen] = useState(false);
    return (
        <>
            <article key={item.id} onClick={() => setOpen(!open)} className="style1">
                <span className="image">
                    <img src={`./assets/images/nahledy/portfolio-${item.id}-nahled.jpg`} alt={item.nazev} />
                </span>
                <div>
                    <h2 style={item.id <= 4 ? { color: "white"} : {}}>{item.nazev}</h2>
                    <div className="content">
                        <p>
                            {item.informace}
                        </p>
                    </div>
                </div>
            </article>
            <FsLightbox
                toggler={open.toString()}
                sources={[
                    `${process.env.PUBLIC_URL}/assets/images/full/portfolio-${item.id}.jpg`
                ]}
                customAttributes={[
                    {
                        title: item.nazev,
                        alt: item.nazev
                    }
                ]}
            />
        </>
    )
}