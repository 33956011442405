import Portfolio from "./components/portfolio.jsx";
import { ReactComponent as WacLogo } from "./icons/wac_logo.svg";
import "./styles/main.css";

function App() {
  return (
    <>
      <div id="wrapper">
        <header id="header">
          <div className="inner">
            <a
              target="_blank"
              without
              rel="noreferrer"
              href="index.html"
              className="logo"
            >
              <span className="symbol">
                <WacLogo />
              </span>
            </a>
          </div>
        </header>

        <div id="main">
          <div className="inner">
            <header>
              <h1>
                <a
                  target="_blank"
                  without
                  rel="noreferrer"
                  href="https://dominikkindl.eu"
                >
                  Dominik Kindl
                </a>
              </h1>
              <p>
                Projekt wac. pojednává využití stávajícího území, které se
                nachází v Praze 6 - Dejvice, konkrétně v ulici Bílá na pozemku
                ve vlastnictví ČVUT v Praze. Tento pozemek je v současné době
                využíván jen zřídka a to jen pro účely uložení stavebních
                materiálů, anebo jako parkovácí plochy pro zaměstnance fakulty
                stavební ČVUT.
              </p>
              <p>
                Vize projektu wac., jenž v plném znění znamená wellness and
                café, řeší toto území jako oblast, kam může zavítat takřka
                kdokoliv, kdo rád tráví čas mezi lidmi ať už v kavárně, nebo při
                relaxu ve wellness.
              </p>
              <p>
                Řešením tedy bylo vytvořit parter, kam může návštěvník zavítat,
                dát si dobrou kávu, zavítat do čítárny s pohledem na ulici Bílou
                nebo díky otevřenému stropu do dolní kavárny a parteru, posedět
                venku pod dominancí stromů, anebo si poslechnout přednášku, nebo
                film v prostoru promítací plochy. Pro jedince, jenž se cítí
                unaveni z dlouhých dní je pak v druhé části objektu navržen
                provoz wellness, a to právě saun. Podle mnoha zdrojů se sauna
                jeví jako velice přínosná pro lidské tělo a jeho relax, a ten se
                právě může hodit lidem, jenž tráví náročné dny v kampusu nebo v
                jiných akademických / pracovních oborech. Saunový provoz nabízí
                možnost zkusit 3 druhy různých typu saunových komor s různými
                teplotami a následovně poté otestovat svou odolnost vůči chladu
                v interérových ochlazovacích bazéncích. Provoz je koncipován
                tak, aby mohl fungovat celoročně, a proto jsou exteriérové
                whirpooly ohřívány. Větší exteriérový bazén je pro plavce s
                protiproudovými tryskami.
              </p>
              <p>
                Přístup je zajištěn z ulice Bílé, pro návštěvníky fakulty
                stavební pak přímo z budovy D. Parkování je upraveno a na
                pozemku se nadále nachází.
              </p>
              <p>
                Hmotově se objekt snaží navázat na stávající typy řešení
                okolních budov, jako je funkcionalistická ZŠ Bílá nebo jako je
                propojení budovy D a H. Tyto objekty v okolí tvoří jednoduché
                hmotové řešení, které bylo inspirací pro tento projekt.
              </p>
              <p>
                Wac. objekty jsou pojednány jako stěnové systémy s hlavním
                stavebním materiálém beton a keramické cihly. Skleněné plochy
                jsou tvořeny hliníkovými okny. Fasádní plocha je opláštěná
                perforovanými plechy s kortenovým odstínem a otvory, které
                dovolují propouštět světlo. Tyto plechy je možné odklopit jako
                okenice elektronicky, abychom dovolili světlu dostat se dovnitř.
                U saunového provozu se tyto plechy dají posunovat.
              </p>
            </header>
            <img
              className="vizualizace image"
              src="./assets/images/plachta_top.webp"
              alt="hlavní vizualizace"
            />
            <Portfolio />
          </div>
        </div>

        <footer id="footer">
          <div className="inner">
            <ul className="copyright">
              <li>&copy; Dominik Kindl</li>
            </ul>
          </div>
        </footer>
      </div>
    </>
  );
}

export default App;
