import React from "react";
import portfolio_data from "../data/portfolio_data.jsx";
import PortfolioItem from "./portfolio_item.jsx";

export default function Portfolio() {
  const portfolio = portfolio_data.map((item) => {
    return <PortfolioItem key={item.id} item={item} />;
  });
  return (
    <section className="tiles">
      {portfolio}
      <article className="style1" onClick={() => {window.open("./assets/images/full/plachta.webp", "_blank", "noreferrer");}}>
        <span className="image">
          <img
            src={`./assets/images/nahledy/plachta-nahled.jpg`}
            alt={"Layout"}
          />
        </span>
        <div>
          <h2 style={{ color: "white" }}>Layout</h2>
          <div className="content">
          </div>
        </div>
      </article>
    </section>
  );
}
